.palette-wrapper {
    width: 100%;
}

.palette {
    float: left;
    width: 100%;
    transition: width 500ms ease-in-out;
    height: 221px;
}

.palette-color1-wrapper {
    height: 100%;
    width: 25%;
    float: left;
}

.palette-color1 {
    width: 414%;
    height: 100%;
    float: left;
    position: relative;
    /* background-color: red; */
}


.palette-color1:hover {
    transform: none !important;
    cursor: auto !important;
    box-shadow: none !important;
    background-color: none !important;
}



.palette-color1--color div {
    width: 100%;
    height: 100%;
    display: block;
    transition: all 200ms ease;
}

.palette-color1--color i {
    color: #fff;
    bottom: 10px;
    right: 15px;
    line-height: 1;
    position: absolute;
    font-size: 32px;
    font-weight: 100;
    transition: all 200ms ease;
    opacity: 0.2;
}

.palette-color1-label--white {
    color: #fff;
}

.palette-color1-label {
    color: #fff;
    top: 22%;
    left: 44%;
    /* line-height: 1; */
    position: relative;
    font-size: 100px;
    font-weight: 400;
    opacity: 0.8;
    text-transform: uppercase;
}

.next {
    color: black;
    padding: 8px 15px;
    margin: 10px;
    border-radius: 5px;
    box-shadow: 0 3px 10px rgb(0 0 0 / 23%), 0 3px 10px rgb(0 0 0 / 16%);
    opacity: 1;
    border: 1px solid rgb(110, 110, 177);
    cursor: pointer;
}

.next:hover {
    color: black;
    background: white;
}