body {
    color: #fff;
    /* background: #d47677; */
    /* background-image: url('../../login2.jpeg');
    background-size: cover; */
    /* background-position: center; */
}

body.loginimag {
    background-image: url('../../login2.jpeg') !important;
    background-size: cover !important;
    height: 100vh;
}

.form-control {
    /* min-height: 41px; */
    background: #fff;
    box-shadow: none !important;
    border-color: #e3e3e3;
}

.form-control:focus {
    border-color: #70c5c0;
}

.form-control,
.btn {
    border-radius: 2px;
}

.login-form {
    /* width: 350px; */
    width: 425px;
    margin: 0 auto;
    padding: 13% 0 30px;
}

.login-form form {
    color: #7a7a7a;
    border-radius: 2px;
    /* margin-bottom: 15px; */
    font-size: 13px;
    background: #fbfbfb;
    /* box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3); */
    /* box-shadow: 2px 2px 9px 0px rgb(0 0 0); */
    padding: 30px 30px 10px 30px;
    position: relative;
}

.login-form h2 {
    font-size: 22px;
    /* margin: 35px 0 25px; */
}

.login-form .avatar {
    position: absolute;
    margin: 0 auto;
    left: 0;
    right: 0;
    top: -50px;
    width: 95px;
    height: 95px;
    border-radius: 50%;
    z-index: 9;
    background: black;
    padding: 2px;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
    border: 1px solid white;
}

.login-form .avatar img {
    width: 100%;
}


.login-form .avatar1 {
    /* position: absolute; */
    /* margin: 0 auto; */
    left: 0;
    right: 0;
    /* top: -50px;
    width: 95px;
    height: 95px;
    border-radius: 20%; */
    /* z-index: 9; */
    background: black;
    padding: 2px;
    margin: 10%;

    /* box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1); */
    /* border: 1px solid white; */
}

.login-form .avatar1 img {
    width: 100%;
}

.login-form input[type="checkbox"] {
    position: relative;
    top: 1px;
}

.login-form .btn,
.login-form .btn:active {
    font-size: 16px;
    font-weight: bold;
    background: rgb(130, 130, 130) !important;
    border: none;
    margin-bottom: 20px;
    width: 45%;
    margin-top: 35px;
    margin-left: 98px;
    color: white;
}

.login-form .btn:hover,
.login-form .btn:focus {
    background: #50b8b3 !important;
}

.login-form a {
    color: #df7878;
    text-decoration: underline;
}

.login-form a:hover {
    text-decoration: none;
}

.login-form form a {
    color: #7a7a7a;
    text-decoration: none;
}

.login-form form a:hover {
    text-decoration: underline;
}

.login-form .bottom-action {
    font-size: 14px;
    margin-top: 35px;

}

p.text-center.small {
    color: white;
}


.toast-message {
    /* background: darkblue; */
    /* color: #fff; */
    font-size: 20px;
    width: 23vw;
    height: 3vw;
    /* margin-right: 50%; */
}