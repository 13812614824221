@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  /* font-family: "Google Sans", Roboto, RobotoDraft, Helvetica, Arial, sans-serif !important; */
  /* font-family: Arial, Helvetica, sans-serif !important; */
  /* font-family: 'Lucida Casual', 'Comic Sans MS' !important; */
  /* font-family: inherit !important; */
  /* font-family: cutive mono !important; */
  /* font-family: 'Open Sans' !important; */
  /* font-family: 'Nunito' !important; */
  font-family: 'Lato' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  user-select: none !important;
}

code {
  font-family: 'Lato' !important;
}

.content_editable_element {
  white-space: pre-wrap;
  /* css-3 */
  white-space: -moz-pre-wrap;
  /* Mozilla, since 1999 */
  white-space: -pre-wrap;
  /* Opera 4-6 */
  white-space: -o-pre-wrap;
  /* Opera 7 */
  word-wrap: break-word;
  /* Internet Explorer 5.5+ */
}



@media print {
  .noprint {
    visibility: hidden;
  }
}