main {
    background-color: white;
    margin: 2%;
    /* border: 1px solid black; */
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}

.main__container {
    padding: 50px;
    margin-top: 10px;
}

.main__container1 {
    padding: 20px !important;
    margin-top: 0px;
}

.main__title {
    display: flex;
    align-items: center;
}

.main__title>h1 {
    font-weight: 500;
    font-size: 34px;
    line-height: 42px;
}



.palette-wrapper {
    width: 100%;
}

.palette {
    float: left;
    width: 100%;
    transition: width 500ms ease-in-out;
    height: 221px;
}

.palette-color-wrapper {
    height: 100%;
    width: 25%;
    float: left;
}

.palette-color {
    width: 414%;
    height: 100%;
    float: left;
    position: relative;
    /* background-color: red; */
}


.palette-color:hover {
    transform: scale(0.9) !important;
    cursor: pointer !important;
    box-shadow: 0 3px 10px rgb(0 0 0 / 23%), 0 3px 10px rgb(0 0 0 / 16%) !important;
    /* background-color: #374046; */
}



.palette-color--color div {
    width: 100%;
    height: 100%;
    display: block;
    transition: all 200ms ease;
}

.palette-color--color i {
    color: #fff;
    bottom: 10px;
    right: 15px;
    line-height: 1;
    position: absolute;
    font-size: 32px;
    font-weight: 100;
    transition: all 200ms ease;
    opacity: 0.2;
}

.palette-color-label--white {
    color: #fff;
}

.palette-color-label {
    color: #fff;
    top: 8px;
    left: 10px;
    line-height: 1;
    position: relative;
    font-size: 16px;
    font-weight: 400;
    opacity: 0.8;
    text-transform: uppercase;
}


.material-icons {

    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
}