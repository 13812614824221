.link {
    color: white !important;
    padding: 8px 15px;
    margin: 8px;
    border-radius: 5px;
    box-shadow: 0 3px 10px rgb(0 0 0 / 23%), 0 3px 10px rgb(0 0 0 / 16%);
    opacity: 1;
    background: #c36a6a;
    text-decoration: none !important;
}

.link a {
    text-decoration: none !important;
}


.link:hover {
    color: black;
    background: white;
}

.linkPrimary {
    color: black !important;
    padding: 8px 15px;
    margin: 8px;
    border-radius: 5px;
    box-shadow: 0 3px 10px rgb(0 0 0 / 23%), 0 3px 10px rgb(0 0 0 / 16%);
    opacity: 1;
    background: blue;
    text-decoration: none !important;
}

.linkPrimary:hover {
    color: black !important;
    /* background: white; */
    box-shadow: 0 1px 3px 0 rgb(60 64 67 / 30%), 0 4px 8px 3px rgb(60 64 67 / 15%);
    background-color: #c2e7ff;
}


.linkPrimary:hover a {
    color: black !important;

}

.linkPrimary a {
    color: white !important;
    text-decoration: none !important;
}