.menuHeader {
  /* height: 60px; */
  z-index: 99999;
  background-color: white;
  position: fixed;
  /* box-shadow: rgba(0, 0, 0, 0.4) 0px 30px 90px; */
  border-bottom: 1px solid #0000002e;
  /* box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px; */
  /* background-image: url('../src/header_bg.png') !important; */
  background-size: cover;
  /* background: #53438321; */
  background: #081a51;
  width: 100%;
  display: flex;
}

h1 {
  margin-top: 0 !important;
  margin-left: 1%;
}

.rightSection {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  width: 100%;
}